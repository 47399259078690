import React, { useState, useEffect, useMemo, useContext } from 'react';
import Card from '../../components/card';
import Container from '../../components/container';
import UserService from '../../app/service/userService';
import MemberService from '../../app/service/memberService';
import LodgeRoleService from '../../app/service/lodgeRoleService';
import LodgePartyService from '../../app/service/lodgePartyService';
import RolePermissionService from '../../app/service/rolePermissionService';
import PRMTSService from "../../app/service/prmtsService";
import { messageError, messageSuccess } from '../../components/toastr';
import { useNavigate } from 'react-router-dom';
import SelectMenu from '../../components/selectMenu';
import { Button } from 'primereact/button';
import { Modal, Form } from 'react-bootstrap';
import { AuthContext } from "../../security/AuthContext";
import * as Constant from '../../config/constants';

const BoardMembers = () => {
    const navigate = useNavigate();
    const userService = useMemo(() => new UserService(), []);
    const memberService = useMemo(() => new MemberService(), []);
    const lodgeRoleService = useMemo(() => new LodgeRoleService(), []);
    const lodgePartyService = useMemo(() => new LodgePartyService(), []);
    const prmtsService = useMemo(() => new PRMTSService(), []);
    const rolePermissionService = useMemo(() => new RolePermissionService(), []);
    const [listMembers, setListMembers] = useState([]);
    const [listMembersParty, setListMembersParty] = useState([]);
    const [rolesWithMembers, setRolesWithMembers] = useState([]);
    const [rolesWithMembers2, setRolesWithMembers2] = useState([]);
    const [rolesWithMembers3, setRolesWithMembers3] = useState([]);
    const [partiesWithMembers, setPartiesWithMembers] = useState([]);
    const [yearDescription, setYearDescription] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showModalParty, setShowModalParty] = useState(false);
    const [selectedParty, setSelectedParty] = useState(null);
    const [selectedPartyMembers, setSelectedPartyMembers] = useState([]);
    const selectOption = { label: 'Selecione', value: 0 };
    const { user, isAuthenticated } = useContext(AuthContext);
    const [canEdit, setCanEdit] = useState(false);

    // Estados para o novo cargo
    const [showModalNewCargo, setShowModalNewCargo] = useState(false);
    const [newCargoDescription, setNewCargoDescription] = useState('');
    const [newCargoAbreviation, setNewCargoAbreviation] = useState('');

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [isAuthenticated, user]);

    const fetchData = async () => {
        try {
            if (isAuthenticated) {
                if (user) {
                    if (user.role !== 1) {
                        const responseMember = await memberService.getById(user.member);
                        const responseCanEdit = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.EDIT_BOARD);
                        setCanEdit(responseCanEdit.data);
                    } else {
                        setCanEdit(true);
                    }
                } else {
                    setCanEdit(false);
                }
            }

            const responseRoles = await lodgeRoleService.getAll();
            const roleData = responseRoles.data;
            // Diretoria (Cargos 1 a 7)
            const filteredRoles = roleData.filter(role => role.order != null && role.order >= 1 && role.order <= 11).sort((a, b) => a.order - b.order);

            const rolesWithMembers = await Promise.all(filteredRoles.map(async (role) => {
                try {
                    const memberResponse = await memberService.getMemberByLodgeRole(role.id);
                    return {
                        roleId: role.id,
                        role: role.description,
                        member: memberResponse.data ? memberResponse.data.id : ''
                    };
                } catch (error) {
                    return {
                        roleId: role.id,
                        role: role.description,
                        member: ''
                    };
                }
            }));


            // Demais cargos (>= 8, exceto 99)
            const filteredRoles2 = roleData
                .filter(role => role.order >= 12 && role.order !== 99)
                .sort((a, b) => a.order - b.order);

            const allOtherRoles = await Promise.all(filteredRoles2.map(async (role) => {
                try {
                    const memberResponse = await memberService.getMemberByLodgeRole(role.id);
                    return {
                        roleId: role.id,
                        role: role.description,
                        order: role.order,
                        member: memberResponse.data ? memberResponse.data.id : ''
                    };
                } catch (error) {
                    return {
                        roleId: role.id,
                        role: role.description,
                        order: role.order,
                        member: ''
                    };
                }
            }));

            // Separando cargos <= 23 e > 23
            const rolesUnder27 = allOtherRoles.filter(rwm => rwm.order < 27);
            const rolesAbove27 = allOtherRoles.filter(rwm => rwm.order >= 27 && rwm.roleId <= 39).sort((a, b) => a.roleId - b.roleId);

            const responseParties = await lodgePartyService.getAll();
            const partyData = responseParties.data;
            const partiesWithMembers = await Promise.all(partyData.map(async (party) => {
                try {
                    const memberResponse = await memberService.getMembersByLodgeParty(party.id);
                    return {
                        partyId: party.id,
                        party: party.description,
                        members: Array.isArray(memberResponse.data) ? memberResponse.data.map(member => member.name).join('\n') : ''
                    };
                } catch (error) {
                    return {
                        partyId: party.id,
                        party: party.description,
                        members: ''
                    };
                }
            }));

            const response = await prmtsService.getByType("YEAR-BOARD");
            if (response.data) {
                setYearDescription(response.data.description);
            }

            setRolesWithMembers(rolesWithMembers);
            setRolesWithMembers2(rolesUnder27);
            setRolesWithMembers3(rolesAbove27);
            setPartiesWithMembers(partiesWithMembers);

            const responseMembers = await memberService.getAll();
            const memberOptions = responseMembers.data.map(member => ({
                label: member.name,
                value: member.id
            }));
            memberOptions.sort((a, b) => a.label.localeCompare(b.label));
            const listAllMembers = [selectOption, ...memberOptions];
            const listAllMembersParty = [...memberOptions];

            setListMembers(listAllMembers);
            setListMembersParty(listAllMembersParty);

        } catch (error) {
            messageError('Erro ao buscar informações.');
        }
    };

    const handleEditYear = () => {
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
        setShowModalParty(false);
        setShowModalNewCargo(false);
        if (showModalParty) {
            updatePartiesWithMembers();
        }
    };

    const handleSave = async () => {
        setShowModal(false);

        const response = await prmtsService.getByType('YEAR-BOARD');

        if (response.data) {
            await prmtsService.update({
                type: response.data.type,
                description: yearDescription,
            }).then(() => {
                messageSuccess('Informações atualizadas com sucesso.');
            }).catch(error => {
                messageError(error.response.data);
            });
        } else {
            await prmtsService.new({
                type: 'YEAR-BOARD',
                description: yearDescription,
            }).then(() => {
                messageSuccess('Informações atualizadas com sucesso.');
            }).catch(error => {
                messageError(error.response.data);
            });
        }
    };

    const handleResetBoardRoles = async () => {
        try {
            await memberService.clearBoardRoles();
            messageSuccess('Todos os cargos da Diretoria foram redefinidos com sucesso.');
            navigate('/board-members');
            return;
        } catch (error) {
            messageError('Erro ao redefinir os cargos da Diretoria.');
        }
    };

    const handleResetOtherRoles = async () => {
        try {
            await memberService.clearOtherRoles();
            messageSuccess('Todos os demais cargos foram redefinidos com sucesso.');
            navigate('/board-members');
            return;
        } catch (error) {
            messageError('Erro ao redefinir os demais cargos.');
        }
    };

    const handleResetAutoridades = async () => {
        try {
            await memberService.clearAutoridades();
            messageSuccess('Todas as autoridades foram redefinidas com sucesso.');
            navigate('/board-members');
            return;
        } catch (error) {
            messageError('Erro ao redefinir as autoridades.');
        }
    };

    const handleResetParties = async () => {
        try {
            await memberService.clearParties();
            messageSuccess('Todas as comissões foram redefinidas com sucesso.');
            navigate('/board-members');
            return;
        } catch (error) {
            messageError('Erro ao redefinir as comissões.');
        }
    };

    const handleEditParty = async (party) => {
        setSelectedParty(party);
        const membersResponse = await memberService.getMembersByLodgeParty(party.partyId);
        setSelectedPartyMembers(Array.isArray(membersResponse.data) ? membersResponse.data.map(member => member.id) : []);
        setShowModalParty(true);
    };

    const handlePartyMemberChange = async (party, memberId) => {
        const responseParty = await lodgePartyService.getByDescription(party.party);
        const member = await memberService.getById(memberId);
        if (member.data.lodgeParty === responseParty.data.id) {
            await memberService.clearPartyMember(memberId);
        } else {
            await memberService.updatePartyMember(responseParty.data.id, memberId);
        }
        const updatedMembers = await memberService.getMembersByLodgeParty(responseParty.data.id);
        setSelectedPartyMembers(Array.isArray(updatedMembers.data) ? updatedMembers.data.map(member => member.id) : []);
    };

    const handleRoleChange = async (roleId, memberId) => {
        try {
            await memberService.updateRoleMember(roleId, memberId);

            messageSuccess('Membro atualizado com sucesso.');
            setRolesWithMembers(prevState =>
                prevState.map(roleWithMember =>
                    roleWithMember.roleId === roleId ? { ...roleWithMember, member: memberId } : roleWithMember
                )
            );
            setRolesWithMembers2(prevState =>
                prevState.map(roleWithMember =>
                    roleWithMember.roleId === roleId ? { ...roleWithMember, member: memberId } : roleWithMember
                )
            );
            setRolesWithMembers3(prevState =>
                prevState.map(roleWithMember =>
                    roleWithMember.roleId === roleId ? { ...roleWithMember, member: memberId } : roleWithMember
                )
            );
        } catch (error) {
            messageError('Erro ao atualizar o membro.');
        }
    };

    const updatePartiesWithMembers = async () => {
        try {
            const responseParties = await lodgePartyService.getAll();
            const partyData = responseParties.data;
            const updatedPartiesWithMembers = await Promise.all(partyData.map(async (party) => {
                try {
                    const memberResponse = await memberService.getMembersByLodgeParty(party.id);
                    return {
                        partyId: party.id,
                        party: party.description,
                        members: Array.isArray(memberResponse.data) ? memberResponse.data.map(member => member.name).join('\n') : ''
                    };
                } catch (error) {
                    return {
                        partyId: party.id,
                        party: party.description,
                        members: ''
                    };
                }
            }));
            setPartiesWithMembers(updatedPartiesWithMembers);
        } catch (error) {
            messageError('Erro ao atualizar informações das comissões.');
        }
    };

    const handleOpenNewCargoModal = () => {
        setNewCargoDescription('');
        setNewCargoAbreviation('');
        setShowModalNewCargo(true);
    }

    const handleSaveNewCargo = async () => {
        if (!newCargoDescription || newCargoDescription.trim() === '') {
            messageError('A descrição do cargo é obrigatória.');
            return;
        }

        try {
            const lodgeRole = {
                description: newCargoDescription,
                abreviation: newCargoAbreviation
            };
            const response = await lodgeRoleService.new(lodgeRole);
            messageSuccess('Cargo criado com sucesso!');

            // Após criar um novo cargo, atualizamos a lista de cargos
            await fetchData();
            setShowModalNewCargo(false);
        } catch (error) {
            messageError('Erro ao criar novo cargo.');
        }
    }

    return (
        <>
            <Container>
                <h1 className="year-header">
                    {yearDescription}
                    {canEdit && (
                        <Button
                            icon="pi pi-pencil"
                            className="small-button btn btn-info"
                            onClick={handleEditYear}
                        />
                    )}
                </h1>
                <div className="row">
                    <div className="col-md-6">
                        <Card
                            title={
                                <div style={{ textAlign: 'center' }}>
                                    <strong>Autoridades</strong>
                                </div>
                            }
                            className="card-board"
                        >
                            <div className="responsive-table">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '40%' }}>Cargo</th>
                                            <th style={{ width: '60%' }}>Irmão</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rolesWithMembers.map((roleWithMember, index) => (
                                            <tr key={index} className={index < 11 ? 'board-role-cell' : ''}>
                                                <td className={index < 11 ? 'board-role-cell' : ''}>{roleWithMember.role}</td>
                                                <td className={index < 11 ? 'board-role-cell' : ''}>
                                                    <SelectMenu
                                                        className="form-control"
                                                        list={listMembers}
                                                        value={roleWithMember.member}
                                                        onChange={(e) => handleRoleChange(roleWithMember.roleId, e.target.value)}
                                                        disabled={!canEdit}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                        {rolesWithMembers2.map((roleWithMember, index) => (
                                            <tr key={index + rolesWithMembers.length}>
                                                <td>{roleWithMember.role}</td>
                                                <td>
                                                    <SelectMenu
                                                        className="form-control"
                                                        list={listMembers}
                                                        value={roleWithMember.member}
                                                        onChange={(e) =>
                                                            handleRoleChange(roleWithMember.roleId, e.target.value)
                                                        }
                                                        disabled={!canEdit}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {canEdit && (
                                <div className="button-group">
                                    <button
                                        onClick={handleResetBoardRoles}
                                        className="btn btn-warning reset-button"
                                    >
                                        Limpar Diretoria
                                    </button>
                                    <button
                                        onClick={handleResetOtherRoles}
                                        className="btn btn-warning reset-button"
                                    >
                                        Limpar Demais Cargos
                                    </button>
                                </div>
                            )}
                        </Card>

                        {/* Nova tabela para cargos > 23, ordenados por ID */}
                        <Card
                            title={
                                <div style={{ textAlign: 'center' }}>
                                    <strong>Autoridades</strong>
                                </div>
                            }
                            className="card-board mt-3"
                        >
                            <div className="responsive-table">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '40%' }}>Cargo</th>
                                            <th style={{ width: '60%' }}>Irmão</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rolesWithMembers3.map((roleWithMember, index) => (
                                            <tr key={index}>
                                                <td>{roleWithMember.role}</td>
                                                <td>
                                                    <SelectMenu
                                                        className="form-control"
                                                        list={listMembers}
                                                        value={roleWithMember.member}
                                                        onChange={(e) =>
                                                            handleRoleChange(roleWithMember.roleId, e.target.value)
                                                        }
                                                        disabled={!canEdit}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {canEdit && (
                                <div className="button-group">
                                    <button
                                        onClick={handleResetAutoridades}
                                        className="btn btn-warning reset-button"
                                    >
                                        Limpar Autoridades
                                    </button>
                                    <Button className="btn btn-success" onClick={handleOpenNewCargoModal} style={{ marginLeft: '10px' }}>
                                        Novo Cargo
                                    </Button>
                                </div>
                            )}
                        </Card>
                    </div>
                    <div className="col-md-6">
                        <Card
                            title={
                                <div style={{ textAlign: 'center' }}>
                                    <strong>Comissões</strong>
                                </div>
                            }
                            className="card-parties"
                        >
                            <div className="responsive-table">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '40%' }}>Comissão</th>
                                            <th style={{ width: '50%' }}>Irmãos</th>
                                            {canEdit && <th style={{ width: '10%' }}>Ação</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {partiesWithMembers.map((partyWithMembers, index) => (
                                            <tr key={index}>
                                                <td>{partyWithMembers.party}</td>
                                                <td>
                                                    {partyWithMembers.members.split('\n').map((member, idx) => (
                                                        <div key={idx}>{member}</div>
                                                    ))}
                                                </td>
                                                {canEdit && (
                                                    <td>
                                                        <Button
                                                            icon="pi pi-pencil"
                                                            className="small-button btn btn-info"
                                                            onClick={() => handleEditParty(partyWithMembers)}
                                                        />
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {canEdit && (
                                <div className="button-group">
                                    <button
                                        onClick={handleResetParties}
                                        className="btn btn-warning reset-button"
                                    >
                                        Limpar Comissões
                                    </button>
                                </div>
                            )}
                        </Card>
                        <div className="legend-container border border-3 mt-3">
                            <div style={{ padding: '10px' }}>
                                <p className="fw-bold">Legenda:</p>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="legend-color-box"></div>
                                    <p style={{ margin: 0 }}>Diretoria</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>

            {/* Modal para editar ano da gestão */}
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar ano da gestão</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formTitle">
                            <Form.Label>Ano inicio / Ano fim</Form.Label>
                            <Form.Control type="text" value={yearDescription} onChange={e => setYearDescription(e.target.value)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Salvar Alterações
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal para editar comissão */}
            <Modal show={showModalParty} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Comissão</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formParty">
                            <Form.Label>Comissão</Form.Label>
                            <Form.Control type="text" value={selectedParty ? selectedParty.party : ''} readOnly />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formMembers">
                            <Form.Label>Membros</Form.Label>
                            {listMembersParty.map(member => (
                                <Form.Check
                                    type="checkbox"
                                    key={member.value}
                                    label={member.label}
                                    checked={selectedPartyMembers.includes(member.value)}
                                    onChange={() => handlePartyMemberChange(selectedParty, member.value)}
                                />
                            ))}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal para inclusão de novo cargo */}
            <Modal show={showModalNewCargo} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Novo Cargo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formNewCargoDescription">
                            <Form.Label>Descrição do Cargo</Form.Label>
                            <Form.Control
                                type="text"
                                value={newCargoDescription}
                                onChange={e => setNewCargoDescription(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formNewCargoAbreviation">
                            <Form.Label>Abreviação (opcional)</Form.Label>
                            <Form.Control
                                type="text"
                                value={newCargoAbreviation}
                                onChange={e => setNewCargoAbreviation(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={handleSaveNewCargo}>
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default BoardMembers;
