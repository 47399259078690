// pdfHeader.js
import { rgb } from 'pdf-lib';

export async function drawHeader(page, fonts, dayTimeSessionDescription) {
    const { fontGreatVibes, fontRegular } = fonts;

    // Posição inicial no eixo Y
    let yPosition = page.getHeight() - 50; // Começa 50 unidades do topo
    let lineSpacing = 10;
    let fontSize = 16;

    // Linha 1
    const line1 = "Loja Maçônica";
    const textWidth1 = fontGreatVibes.widthOfTextAtSize(line1, 10);
    page.drawText(line1, {
        x: (page.getWidth() - textWidth1) / 2,
        y: yPosition,
        size: fontSize,
        font: fontGreatVibes,
        color: rgb(0, 0, 0),
    });
    yPosition -= fontSize + lineSpacing;

    // Linha 1_1
    const line1_1 = "Professor Hermínio Blackman, nº 1761";
    const textWidth1_1 = fontGreatVibes.widthOfTextAtSize(line1_1, fontSize);
    page.drawText(line1_1, {
        x: (page.getWidth() - textWidth1_1) / 2,
        y: yPosition,
        size: fontSize,
        font: fontGreatVibes,
        color: rgb(0, 0, 0),
    });
    fontSize = 14;
    yPosition -= fontSize + lineSpacing + 4;

    // Linha 2
    const line2 = "FEDERADA AO GOB e JURISDICIONADA AO GOB-ES";
    const fontSize2 = 10;
    const textWidth2 = fontRegular.widthOfTextAtSize(line2, fontSize2);
    page.drawText(line2, {
        x: (page.getWidth() - textWidth2) / 2,
        y: yPosition,
        size: fontSize2,
        font: fontRegular,
        color: rgb(0, 0, 0),
    });
    yPosition -= fontSize2 + lineSpacing;

    // Linha 3
    const line3 = `Rito EAA - Reuniões às ${dayTimeSessionDescription}`;
    const textWidth3 = fontRegular.widthOfTextAtSize(line3, fontSize2);
    page.drawText(line3, {
        x: (page.getWidth() - textWidth3) / 2,
        y: yPosition,
        size: fontSize2,
        font: fontRegular,
        color: rgb(0, 0, 0),
    });
    yPosition -= fontSize2 + lineSpacing;

    // Linha 4
    const line4 = "Rua Presidente Lima, 1010, Centro, Vila Velha/ES. CEP 29.100-330";
    const textWidth4 = fontRegular.widthOfTextAtSize(line4, fontSize2);
    page.drawText(line4, {
        x: (page.getWidth() - textWidth4) / 2,
        y: yPosition,
        size: fontSize2,
        font: fontRegular,
        color: rgb(0, 0, 0),
    });

    // Retorna a posição Y atualizada para uso posterior
    return yPosition;
}
