import React, { useState, useEffect, useMemo, useContext } from "react";
import { Table, Form, Modal, Button, Spinner } from "react-bootstrap";
import SelectMenu from "../../components/selectMenu";
import { messageError, messageSuccess } from "../../components/toastr";
import TaxesPaymentsService from "../../app/service/taxesPaymentsService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AuthContext } from "../../security/AuthContext";
import MemberService from "../../app/service/memberService";
import * as Constant from '../../config/constants';
import RolePermissionService from '../../app/service/rolePermissionService';



const TaxPayments = ({ taxes, members }) => {
    const { user, isAuthenticated } = useContext(AuthContext);

    const [selectedMember, setSelectedMember] = useState("");
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [filteredPayments, setFilteredPayments] = useState([]);
    const [viewAllMembers, setViewAllMembers] = useState(false);
    const rolePermissionService = useMemo(() => new RolePermissionService(), []);

    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [paymentDate, setPaymentDate] = useState(new Date());
    const [receipt, setReceipt] = useState("");
    const [paymentType, setPaymentType] = useState("Depósito");
    const [membersStatus, setMembersStatus] = useState([]);
    const [loadingConfirm, setLoadingConfirm] = useState(false);
    const [loading, setLoading] = useState(false);

    const [showBulkModal, setShowBulkModal] = useState(false);
    const [bulkPaymentDate, setBulkPaymentDate] = useState(new Date());
    const [bulkReceipt, setBulkReceipt] = useState("");
    const [bulkPaymentType, setBulkPaymentType] = useState("Depósito");

    const [pendingPayments, setPendingPayments] = useState([]);
    const [selectedPendingIds, setSelectedPendingIds] = useState([]);

    const [showPixModal, setShowPixModal] = useState(false);
    const [pixQrCode, setPixQrCode] = useState("");
    const [pixCopyPaste, setPixCopyPaste] = useState("");
    const [pixPaymentDate, setPixPaymentDate] = useState(new Date());
    const [pixReceipt, setPixReceipt] = useState("");
    const [pixPaymentType, setPixPaymentType] = useState("Depósito");

    // Valor total gerado pelo Pix (se quiser continuar usando)
    const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);

    // Novo modal de exibição do QR Pix
    const [showPixDisplayModal, setShowPixDisplayModal] = useState(false);
    const [pixPaymentConfirmed, setPixPaymentConfirmed] = useState(false);

    const taxesPaymentsService = useMemo(() => new TaxesPaymentsService(), []);
    const memberService = useMemo(() => new MemberService(), []);

    // Cálculo do range de anos
    const yearOptions = useMemo(() => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = currentYear + 1; i >= currentYear - 5; i--) {
            years.push(i);
        }
        years.sort((a, b) => a - b);
        return years;
    }, []);

    const checkAllMembersOverdue = async () => {
        if (!members || members.length === 0) {
            setMembersStatus([]);
            return;
        }
        const today = new Date();
        const statusPromises = members.map(async (member) => {
            if (!member.id) {
                return { id: member.id, overdue: false };
            }
            try {
                const resp = await taxesPaymentsService.filterPayments(member.id, selectedYear);
                const payments = resp.data || [];
                const isOverdue = payments.some((p) => {
                    const expireDate = p.dtExpire ? new Date(p.dtExpire) : null;
                    return expireDate && expireDate < today && !p.dtPayment;
                });
                return { id: member.id, overdue: isOverdue };
            } catch {
                return { id: member.id, overdue: false };
            }
        });
        const result = await Promise.all(statusPromises);
        setMembersStatus(result);
    };

    const fetchFilteredPayments = async () => {
        try {
            setLoading(true);
            if (!selectedMember) {
                setFilteredPayments([]);
                return;
            }
            const response = await taxesPaymentsService.filterPayments(selectedMember, selectedYear);
            const payments = response.data || [];
            const convertedPayments = payments.map((payment) => ({
                ...payment,
                dtExpire: payment.dtExpire ? new Date(payment.dtExpire) : null,
                dtPayment: payment.dtPayment ? new Date(payment.dtPayment) : null,
            }));
            setFilteredPayments(convertedPayments);
        } catch (error) {
            console.error("Erro ao buscar pagamentos:", error);
            const errorMessage = error.response?.data || "Ocorreu um erro ao buscar pagamentos.";
            messageError(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (isAuthenticated) {
                if (user) {
                    if (user.role !== 1) {
                        const responseMember = await memberService.getById(user.member);

                        const responseCanViewAll = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.VIEW_ALL_PAYMENTS);

                        setViewAllMembers(responseCanViewAll.data);
                        if (selectedMember === "") {
                            setSelectedMember(responseMember.data.id);
                        }
                    } else {
                        setViewAllMembers(true);
                    }
                }
            }

            if (members && members.length > 0 && selectedYear) {
                checkAllMembersOverdue();
            }

            if (selectedMember && selectedYear) {
                setLoading(true);
                setFilteredPayments([]);
                try {
                    const response = await taxesPaymentsService.filterPayments(
                        selectedMember,
                        selectedYear
                    );
                    const payments = response.data || [];
                    const convertedPayments = payments.map((payment) => ({
                        ...payment,
                        dtExpire: payment.dtExpire ? new Date(payment.dtExpire) : null,
                        dtPayment: payment.dtPayment ? new Date(payment.dtPayment) : null,
                    }));
                    setFilteredPayments(convertedPayments);
                } catch (error) {
                    console.error("Erro ao buscar pagamentos:", error);
                    const errorMessage =
                        error.response?.data || "Ocorreu um erro ao buscar pagamentos.";
                    messageError(errorMessage);
                } finally {
                    setLoading(false);
                }
            } else {
                setFilteredPayments([]);
            }
        };
        fetchData();
        // eslint-disable-next-line
    }, [members, selectedYear, selectedMember, isAuthenticated, user]);

    const getTextColor = (payment) => {
        if (!payment || !payment.dtExpire) return "black";
        const today = new Date();
        if (payment.dtPayment) return "green";
        if (payment.dtExpire < today) return "red";
        return "black";
    };

    const handleCellClick = (payment) => {
        if (!payment) return;
        setSelectedPayment(payment);
        setPaymentDate(payment.dtExpire || new Date());
        setReceipt("");
        setPaymentType("Depósito");
        setShowModal(true);
    };

    const handleConfirmPayment = async () => {
        if (!selectedPayment) return;
        try {
            setLoadingConfirm(true);
            const updatedPayment = {
                ...selectedPayment,
                dtPayment: paymentDate,
                receipt: receipt,
                paymentType: paymentType,
            };
            await taxesPaymentsService.updatePayment(selectedPayment.id, updatedPayment);
            messageSuccess("Pagamento atualizado com sucesso!");
            setShowModal(false);
            setLoading(true);
            fetchFilteredPayments();
        } catch (error) {
            console.error("Erro ao atualizar pagamento:", error);
            const errorMessage =
                error.response?.data || "Ocorreu um erro ao atualizar o pagamento.";
            messageError(errorMessage);
        } finally {
            setLoadingConfirm(false);
        }
    };

    const handleDeletePayment = async () => {
        if (!selectedPayment || !selectedPayment.id) return;
        try {
            await taxesPaymentsService.deletePayment(selectedPayment.id);
            messageSuccess("Pagamento removido com sucesso!");
            setShowDeleteModal(false);
            setShowModal(false);
            setLoading(true);
            fetchFilteredPayments();
        } catch (error) {
            console.error("Erro ao remover pagamento:", error);
            const errorMessage =
                error.response?.data || "Ocorreu um erro ao remover o pagamento.";
            messageError(errorMessage);
        }
    };

    const openBulkModal = () => {
        const pending = filteredPayments.filter((p) => !p.dtPayment && p.amount > 0);
        // Ordena pela data de vencimento
        pending.sort((a, b) => {
            const aTime = a.dtExpire ? a.dtExpire.getTime() : Infinity;
            const bTime = b.dtExpire ? b.dtExpire.getTime() : Infinity;
            return aTime - bTime;
        });
        setPendingPayments(pending);
        setSelectedPendingIds([]);
        setBulkPaymentDate(new Date());
        setBulkReceipt("");
        setBulkPaymentType("Depósito");
        setShowBulkModal(true);
    };

    const toggleSelectPending = (paymentId) => {
        setSelectedPendingIds((prev) => {
            if (prev.includes(paymentId)) {
                return prev.filter((id) => id !== paymentId);
            } else {
                return [...prev, paymentId];
            }
        });
    };

    const handleBulkPayment = async () => {
        if (selectedPendingIds.length === 0) {
            messageError("Nenhum pagamento foi selecionado!");
            return;
        }
        try {
            setLoadingConfirm(true);
            const paymentsToUpdate = selectedPendingIds.map((payId) => {
                const paymentToUpdate = pendingPayments.find((p) => p.id === payId);
                return {
                    id: paymentToUpdate.id,
                    dtPayment: bulkPaymentDate,
                    receipt: bulkReceipt,
                    paymentType: bulkPaymentType,
                };
            });
            await taxesPaymentsService.updatePaymentsBulk(paymentsToUpdate);
            messageSuccess("Pagamentos múltiplos realizados com sucesso!");
            setShowBulkModal(false);
            setLoading(true);
            fetchFilteredPayments();
        } catch (err) {
            console.error("Erro ao pagar múltiplos:", err);
            const errorMessage =
                err.response?.data || "Ocorreu um erro ao pagar múltiplos pagamentos.";
            messageError(errorMessage);
        } finally {
            setLoadingConfirm(false);
        }
    };

    const handleGeneratePix = async () => {
        try {
            setLoadingConfirm(true);
            const totalAmount = pendingPayments
                .filter((p) => selectedPendingIds.includes(p.id) && p.amount > 0)
                .reduce((acc, p) => acc + p.amount, 0);
            setTotalPaymentAmount(totalAmount);
            const requestPayload = {
                paymentIds: selectedPendingIds,
                totalAmount: totalAmount,
                memberId: selectedMember,
            };
            const response = await taxesPaymentsService.generatePix(requestPayload);
            setPixQrCode(response.data.qrCodeBase64);
            setPixCopyPaste(response.data.pixPayload);
            messageSuccess("QR Pix gerado com sucesso!");
            // Fecha modal de seleção e abre exibição do QR
            setShowBulkModal(false);
            setPixPaymentConfirmed(false);
            setShowPixDisplayModal(true);
        } catch (err) {
            messageError("Erro ao gerar Pix: " + (err.response?.data || err.message));
        } finally {
            setLoadingConfirm(false);
        }
    };

    // Total selecionado que atualiza dinamicamente
    const totalSelectedAmount = useMemo(() => {
        return pendingPayments
            .filter((p) => selectedPendingIds.includes(p.id))
            .reduce((acc, p) => acc + p.amount, 0);
    }, [pendingPayments, selectedPendingIds]);

    const membersList = useMemo(() => {
        if (!members || members.length === 0) {
            return [{ label: "Selecione", value: "" }];
        }
        return [
            { label: "Selecione", value: "" },
            ...members.map((member) => {
                const status = membersStatus.find((s) => s.id === member.id);
                const overdue = status && status.overdue;
                return {
                    label: member.name + (overdue ? " - INADIMPLENTE" : ""),
                    value: member.id,
                };
            }),
        ];
    }, [members, membersStatus]);

    const groupedData = useMemo(() => {
        const groups = {};
        if (!filteredPayments || !Array.isArray(filteredPayments)) return groups;
        if (!taxes || !Array.isArray(taxes)) return groups;
        const paymentsInSelectedYear = filteredPayments.filter((payment) => {
            return payment.dtExpire && payment.dtExpire.getFullYear() === selectedYear;
        });
        paymentsInSelectedYear.forEach((payment) => {
            const taxGroup = payment.tax ? payment.tax.taxGroup : "Outros";
            const displayDescription = payment.description
                ? payment.description
                : payment.tax
                    ? payment.tax.description
                    : "N/A";
            if (!groups[taxGroup]) {
                groups[taxGroup] = {};
            }
            if (!groups[taxGroup][displayDescription]) {
                groups[taxGroup][displayDescription] = {
                    taxId: payment.tax ? payment.tax.id : null,
                    months: Array(12).fill(null),
                };
            }
            if (payment.dtExpire) {
                const month = payment.dtExpire.getMonth();
                groups[taxGroup][displayDescription].months[month] = payment;
            }
        });
        Object.keys(groups).forEach((group) => {
            // Filtra apenas descrições que tenham algum mês preenchido
            groups[group] = Object.fromEntries(
                Object.entries(groups[group]).filter(([_, data]) =>
                    data.months.some((month) => month !== null)
                )
            );
            if (Object.keys(groups[group]).length === 0) {
                delete groups[group];
            }
        });
        return groups;
    }, [filteredPayments, taxes, selectedYear]);

    const monthlyTotals = useMemo(() => {
        const totals = Array(12).fill(0);
        Object.keys(groupedData).forEach((group) => {
            const descriptions = groupedData[group];
            Object.values(descriptions).forEach((data) => {
                data.months.forEach((payment, i) => {
                    if (payment && payment.amount > 0) {
                        totals[i] += payment.amount;
                    }
                });
            });
        });
        return totals;
    }, [groupedData]);

    const monthlyTotalsPaid = useMemo(() => {
        const totals = Array(12).fill(0);
        Object.keys(groupedData).forEach((group) => {
            const descriptions = groupedData[group];
            Object.values(descriptions).forEach((data) => {
                data.months.forEach((payment, i) => {
                    if (payment && payment.amount > 0 && payment.dtPayment) {
                        totals[i] += payment.amount;
                    }
                });
            });
        });
        return totals;
    }, [groupedData]);

    const monthlyTotalsPending = useMemo(() => {
        const totals = Array(12).fill(0);
        Object.keys(groupedData).forEach((group) => {
            const descriptions = groupedData[group];
            Object.values(descriptions).forEach((data) => {
                data.months.forEach((payment, i) => {
                    if (payment && payment.amount > 0 && !payment.dtPayment) {
                        totals[i] += payment.amount;
                    }
                });
            });
        });
        return totals;
    }, [groupedData]);

    const isMonthOverdue = (monthIndex) => {
        const now = new Date();
        for (const groupKey of Object.keys(groupedData)) {
            const descriptions = groupedData[groupKey];
            for (const data of Object.values(descriptions)) {
                const payment = data.months[monthIndex];
                if (payment && !payment.dtPayment && payment.dtExpire < now) {
                    return true;
                }
            }
        }
        return false;
    };

    return (
        <div>
            {/* Seletor de membro e ano */}
            <div
                style={{
                    display: "flex",
                    alignItems: "left",
                    gap: "20px",
                    marginBottom: "20px",
                    width: "50%",
                }}
            >
                <Form.Group controlId="selectMember" style={{ flex: 1 }}>
                    <Form.Label>Irmão:</Form.Label>
                    <SelectMenu
                        style={{ width: "100%", height: "38px" }}
                        className="form-control"
                        list={membersList}
                        value={selectedMember}
                        //enabled={viewAllMembers}
                        disabled={!viewAllMembers}
                        onChange={(e) => {
                            const val = e.target.value;
                            setSelectedMember(val ? parseInt(val, 10) : "");
                        }}
                    />
                </Form.Group>

                <Form.Group controlId="selectYear" style={{ flex: 0.2 }}>
                    <Form.Label>Ano:</Form.Label>
                    <SelectMenu
                        style={{ width: "100%", height: "38px" }}
                        className="form-control"
                        list={yearOptions.map((year) => ({
                            label: year.toString(),
                            value: year,
                        }))}
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(parseInt(e.target.value, 10))}
                    />
                </Form.Group>
            </div>

            {/* Botão de realizar pagamentos em lote */}
            {filteredPayments.some((p) => !p.dtPayment && p.amount > 0) && (
                <Button variant="warning" onClick={openBulkModal}>
                    Realizar Pagamentos
                </Button>
            )}

            {/* Tabela */}
            <div style={{ marginTop: "20px" }}>
                {loading ? (
                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                        <Spinner animation="border" role="status" />
                        <div>Carregando...</div>
                    </div>
                ) : filteredPayments.length > 0 ? (
                    <>
                        <Table
                            striped
                            bordered
                            hover
                            size="sm"
                            style={{ border: "3px solid black", borderCollapse: "collapse" }}
                        >
                            <thead>
                                <tr>
                                    <th>Fonte</th>
                                    <th>Descrição</th>
                                    {[...Array(12).keys()].map((i) => (
                                        <th key={i} style={{ textAlign: "center" }}>
                                            {new Date(0, i)
                                                .toLocaleString("pt-BR", { month: "long" })
                                                .toUpperCase()}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(groupedData)
                                    .sort((a, b) => a.localeCompare(b))
                                    .map((group) => {
                                        const descriptions = groupedData[group];
                                        const sortedDescriptions = Object.entries(descriptions).sort(
                                            (a, b) => a[1].taxId - b[1].taxId
                                        );
                                        return (
                                            <React.Fragment key={group}>
                                                {sortedDescriptions.map(([description, data], index) => (
                                                    <tr key={`${group}-${description}`}>
                                                        {index === 0 && (
                                                            <td
                                                                rowSpan={sortedDescriptions.length}
                                                                style={{
                                                                    textAlign: "center",
                                                                    verticalAlign: "middle",
                                                                }}
                                                            >
                                                                {group}
                                                            </td>
                                                        )}
                                                        <td className="align-middle">{description}</td>
                                                        {data.months.map((payment, i) => (
                                                            <td
                                                                key={i}
                                                                style={{
                                                                    textAlign: "center",
                                                                    color: getTextColor(payment),
                                                                    cursor:
                                                                        payment && viewAllMembers
                                                                            ? "pointer"
                                                                            : "default",
                                                                }}
                                                                onClick={
                                                                    viewAllMembers && payment
                                                                        ? () => handleCellClick(payment)
                                                                        : undefined
                                                                }
                                                            >
                                                                {payment?.amount > 0
                                                                    ? Intl.NumberFormat("pt-BR", {
                                                                        style: "currency",
                                                                        currency: "BRL",
                                                                    }).format(payment.amount)
                                                                    : ""}
                                                                <br />
                                                                {payment?.dtPayment
                                                                    ? payment.dtPayment.toLocaleDateString("pt-BR")
                                                                    : payment?.dtExpire
                                                                        ? payment.dtExpire.toLocaleDateString("pt-BR")
                                                                        : ""}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        );
                                    })}
                                {/* Totais */}
                                <tr style={{ borderTop: "3px solid black" }}>
                                    <td
                                        colSpan={2}
                                        style={{ fontWeight: "bold", textAlign: "center" }}
                                    >
                                        Total
                                    </td>
                                    {monthlyTotals.map((total, i) => (
                                        <td
                                            key={i}
                                            style={{ textAlign: "center", fontWeight: "bold" }}
                                        >
                                            {total > 0
                                                ? Intl.NumberFormat("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                }).format(total)
                                                : ""}
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td
                                        colSpan={2}
                                        style={{
                                            fontWeight: "bold",
                                            textAlign: "center",
                                            color: "green",
                                        }}
                                    >
                                        Total pago
                                    </td>
                                    {monthlyTotalsPaid.map((total, i) => (
                                        <td
                                            key={i}
                                            style={{
                                                textAlign: "center",
                                                fontWeight: "bold",
                                                color: "green",
                                            }}
                                        >
                                            {total > 0
                                                ? Intl.NumberFormat("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                }).format(total)
                                                : ""}
                                        </td>
                                    ))}
                                </tr>
                                <tr style={{ borderBottom: "3px solid black" }}>
                                    <td
                                        colSpan={2}
                                        style={{ fontWeight: "bold", textAlign: "center" }}
                                    >
                                        Total pendente
                                    </td>
                                    {monthlyTotalsPending.map((total, i) => {
                                        const overdue = isMonthOverdue(i);
                                        return (
                                            <td
                                                key={i}
                                                style={{
                                                    textAlign: "center",
                                                    fontWeight: "bold",
                                                    color: overdue ? "red" : "black",
                                                }}
                                            >
                                                {total > 0
                                                    ? Intl.NumberFormat("pt-BR", {
                                                        style: "currency",
                                                        currency: "BRL",
                                                    }).format(total)
                                                    : ""}
                                            </td>
                                        );
                                    })}
                                </tr>
                            </tbody>
                        </Table>

                        <div style={{ marginTop: "20px" }}>
                            <p>
                                <span style={{ color: "green" }}>●</span> Pago <br />
                                <span style={{ color: "red" }}>●</span> Atrasado <br />
                                <span style={{ color: "black" }}>●</span> Em aberto
                            </p>
                        </div>
                    </>
                ) : (
                    <p>Nenhum pagamento encontrado para os critérios selecionados.</p>
                )}
            </div>

            {/* Modal de pagamento individual */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Pagamento Realizado?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="paymentDate">
                        <Form.Label>Data do Pagamento:</Form.Label>
                        <DatePicker
                            selected={paymentDate}
                            onChange={(date) => setPaymentDate(date)}
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                        />
                    </Form.Group>
                    <br />
                    <Form.Group controlId="receiptNumber">
                        <Form.Label>Número do Recibo</Form.Label>
                        <Form.Control
                            type="text"
                            value={receipt}
                            onChange={(e) => setReceipt(e.target.value)}
                            placeholder="Informe o número do recibo"
                        />
                    </Form.Group>
                    <br />
                    <Form.Group controlId="paymentType">
                        <Form.Label>Tipo Monetário</Form.Label>
                        <Form.Control
                            as="select"
                            value={paymentType}
                            onChange={(e) => setPaymentType(e.target.value)}
                        >
                            <option value="Depósito">Depósito</option>
                            <option value="Em espécie">Em espécie</option>
                        </Form.Control>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={() => setShowDeleteModal(true)}>
                        Excluir
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleConfirmPayment}
                        disabled={loadingConfirm}
                    >
                        {loadingConfirm ? "Confirmando..." : "Confirmar"}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de confirmação de exclusão */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar Exclusão</Modal.Title>
                </Modal.Header>
                <Modal.Body>Tem certeza que deseja excluir este pagamento?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={handleDeletePayment}>
                        Excluir
                    </Button>
                </Modal.Footer>
            </Modal>

            {/*
        Modal de pagamentos múltiplos, usando "dialogClassName" para ter largura
        extra, e um layout com flex. A coluna da esquerda é rolável,
        e a coluna da direita fica "presa" (sticky) no topo.
      */}
            <Modal
                show={showBulkModal}
                onHide={() => setShowBulkModal(false)}
                centered
                dialogClassName="modal-xl" // ou "modal-lg", "modal-xl", etc.
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar Pagamento</Modal.Title>
                </Modal.Header>

                {/*
          Para o sticky funcionar, precisamos evitar que o próprio .modal-body
          todo seja rolável. Em vez disso, faremos a *coluna esquerda* rolável,
          enquanto a coluna direita fica travada (sticky).
        */}
                <Modal.Body style={{ overflow: "hidden" }}>
                    <div style={{ display: "flex" }}>
                        {/* COLUNA ESQUERDA (rolável) */}
                        <div
                            style={{
                                flex: 1,
                                maxHeight: "70vh",
                                overflowY: "auto",
                                paddingRight: "2rem",
                                borderRight: "1px solid #ccc",
                            }}
                        >
                            {viewAllMembers && filteredPayments.some((p) => !p.dtPayment && p.amount > 0) && (
                                <>
                                    <Form.Group controlId="bulkPaymentDate">
                                        <Form.Label>Data do Pagamento:</Form.Label>
                                        <DatePicker
                                            selected={bulkPaymentDate}
                                            onChange={(date) => setBulkPaymentDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control"
                                        />
                                    </Form.Group>
                                    <br />
                                    <Form.Group controlId="bulkReceipt">
                                        <Form.Label>Número do Recibo</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={bulkReceipt}
                                            onChange={(e) => setBulkReceipt(e.target.value)}
                                            placeholder="Informe o número do recibo"
                                        />
                                    </Form.Group>
                                    <br />
                                    <Form.Group controlId="bulkPaymentType">
                                        <Form.Label>Tipo Monetário</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={bulkPaymentType}
                                            onChange={(e) => setBulkPaymentType(e.target.value)}
                                        >
                                            <option value="Depósito">Depósito</option>
                                            <option value="Em espécie">Em espécie</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <br />
                                </>
                            )}

                            {pendingPayments.length === 0 && (
                                <p>Não há pagamentos pendentes para este membro.</p>
                            )}
                            {pendingPayments.map((payment) => {
                                const labelText = `
${payment.tax?.taxGroup} - ${payment.tax?.description || payment.description}
- Venc: ${payment.dtExpire
                                        ? payment.dtExpire.toLocaleDateString("pt-BR")
                                        : ""
                                    }
- Valor: ${payment.amount
                                        ? Intl.NumberFormat("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        }).format(payment.amount)
                                        : ""
                                    }
`;
                                return (
                                    <div
                                        key={payment.id}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            marginBottom: "1rem",
                                        }}
                                    >
                                        <input
                                            type="checkbox"
                                            style={{ transform: "scale(1.4)", cursor: "pointer", marginLeft: "10px" }}
                                            checked={selectedPendingIds.includes(payment.id)}
                                            onChange={() => toggleSelectPending(payment.id)}
                                        />
                                        <span style={{ whiteSpace: "pre-wrap", marginLeft: "20px" }}>
                                            {labelText}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>

                        {/* COLUNA DIREITA (painel fixo) */}
                        <div style={{ width: "30%", paddingLeft: "2rem", position: "relative" }}>
                            {/*
                Para funcionar como "fixo" ao rolar, usamos "position: sticky" e "top: 0".
                Assim, este painel não rola, apenas o lado esquerdo rola.
              */}
                            <div style={{ position: "sticky", top: 0 }}>
                                <h5 style={{ marginBottom: "1.5rem" }}>Total Selecionado</h5>

                                <div
                                    style={{
                                        fontSize: "1.5rem",
                                        fontWeight: "bold",
                                        color: "#333",
                                        marginBottom: "2rem",
                                    }}
                                >
                                    {Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                    }).format(totalSelectedAmount)}
                                </div>

                                {/* Botões fixos logo abaixo do total */}
                                <div style={{ marginTop: "2rem" }}>
                                    <Button variant="info" onClick={handleGeneratePix} disabled={loadingConfirm}>
                                        {loadingConfirm ? "Gerando Pix..." : "Gerar QR Pix"}
                                    </Button>

                                    <br />
                                    {viewAllMembers &&
                                        filteredPayments.some((p) => !p.dtPayment && p.amount > 0) && (
                                            <Button
                                                variant="success"
                                                style={{ marginTop: "1rem" }}
                                                onClick={handleBulkPayment}
                                                disabled={loadingConfirm}
                                            >
                                                {loadingConfirm ? "Confirmando..." : "Confirmar Pagamento"}
                                            </Button>
                                        )}
                                    <br />
                                    <Button
                                        variant="secondary"
                                        style={{ marginTop: "1rem" }}
                                        onClick={() => setShowBulkModal(false)}
                                    >
                                        Cancelar
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Modal final de exibição do Pix */}
            <Modal
                show={showPixDisplayModal}
                onHide={() => setShowPixDisplayModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Pagamento via Pix</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: "center" }}>
                    {!pixPaymentConfirmed ? (
                        <>
                            <h5>
                                Valor total a ser pago:
                                <div style={{ marginTop: "10px" }}>
                                    <strong>
                                        {Intl.NumberFormat("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        }).format(totalPaymentAmount)}
                                    </strong>
                                </div>
                            </h5>
                            {pixQrCode && (
                                <div style={{ marginBottom: "10px" }}>
                                    <img
                                        src={`data:image/png;base64,${pixQrCode}`}
                                        alt="Pix QR Code"
                                        style={{ maxWidth: "200px" }}
                                    />
                                </div>
                            )}
                            {pixCopyPaste && (
                                <Form.Group>
                                    <Form.Label>Pix Copia e Cola:</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        readOnly
                                        value={pixCopyPaste}
                                        onFocus={(e) => e.target.select()}
                                    />
                                </Form.Group>
                            )}
                        </>
                    ) : (
                        <>
                            <div style={{ fontSize: "60px", color: "green", marginBottom: "20px" }}>
                                &#10004;
                            </div>
                            <h5>Favor enviar o comprovante para o tesoureiro.</h5>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {!pixPaymentConfirmed ? (
                        <Button variant="success" onClick={() => setPixPaymentConfirmed(true)}>
                            Confirmar Pagamento
                        </Button>
                    ) : (
                        <Button variant="primary" onClick={() => setShowPixDisplayModal(false)}>
                            Fechar
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default TaxPayments;
