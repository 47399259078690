//MemberService.js:

import ApiService from '../apiservice';

class MemberService extends ApiService {

    constructor() {
        super('/member');
    }

    getAll() {
        return this.get(`${this.apiurl}/all`);
    }

    getAllActive() {
        return this.get(`${this.apiurl}/all-active`);
    }

    getById(id) {
        return this.get(`${this.apiurl}/id/${id}`);
    }

    getMemberByLodgeRole(id) {
        return this.get(`${this.apiurl}/lodgerole/${id}`);
    }

    getMembersByLodgeParty(id) {
        return this.get(`${this.apiurl}/lodgeparty/${id}`);
    }

    validateEmail(email) {
        return this.get(`${this.apiurl}/email/${email}`);
    }

    validateCpf(cpf) {
        return this.get(`${this.apiurl}/cpf/${cpf}`);
    }

    validateCim(cim) {
        return this.get(`${this.apiurl}/cim/${cim}`);
    }
    clearBoardRoles() {
        this.put(`${this.apiurl}/clearboardroles`);
    }
    clearOtherRoles() {
        this.put(`${this.apiurl}/clearotherroles`);
    }
    clearParties() {
        this.put(`${this.apiurl}/clearparties`);
    }

    clearPartyMember(memberId) {
        this.put(`${this.apiurl}/clearpartymember/${memberId}`);
    }

    updateRoleMember(roleId, memberId) {
        return this.put(`${this.apiurl}/updateRoleMember?roleId=${roleId}&memberId=${memberId}`);
    }

    updatePartyMember(partyId, memberId) {
        return this.put(`${this.apiurl}/updatePartyMember?partyId=${partyId}&memberId=${memberId}`);
    }

    findMembers(MemberFilter) {
        if (MemberFilter.name ||
            MemberFilter.cim ||
            MemberFilter.degree ||
            MemberFilter.status
        ) {
            let params = `?`;
            let count = 0
            if (MemberFilter.name) {
                params += `name=${MemberFilter.name}`;
                count++;
            }

            if (MemberFilter.cim) {
                if (count > 0)
                    params += `&`
                params += `cim=${MemberFilter.cim}`
                count++;
            }

            if (MemberFilter.degree) {
                if (count > 0)
                    params += `&`
                params += `degree=${MemberFilter.degree}`
                count++;
            }

            if (MemberFilter.status) {
                if (count > 0)
                    params += `&`
                params += `status=${MemberFilter.status}`
            }
            return this.get(`${this.apiurl}${params}`)
        }
        return this.get(`${this.apiurl}/all`);
    };

    findMembersAttendance(MemberFilter) {
        if (MemberFilter.name ||
            MemberFilter.cim ||
            MemberFilter.degree
        ) {
            let params = `?`;
            let count = 0
            if (MemberFilter.name) {
                params += `name=${MemberFilter.name}`;
                count++;
            }

            if (MemberFilter.cim) {
                if (count > 0)
                    params += `&`
                params += `cim=${MemberFilter.cim}`
                count++;
            }

            if (MemberFilter.degree) {
                if (count > 0)
                    params += `&`
                params += `degree=${MemberFilter.degree}`
                count++;
            }
            return this.get(`${this.apiurl}/attendance${params}`)
        }
        return this.get(`${this.apiurl}/all-active`);
    };

    getMaritalStatusList() {
        return [
            { label: "Selecione", value: "" },
            { label: "Solteiro", value: "Solteiro" },
            { label: "União Estável", value: "União Estável" },
            { label: "Casado", value: "Casado" },
            { label: "Separado", value: "Separado" },
            { label: "Divorciado", value: "Divorciado" },
            { label: "Viúvo", value: "Viúvo" }
        ];
    }
    getBloodTypeList() {
        return [
            { label: "Selecione", value: "" },
            { label: "A+", value: "A+" },
            { label: "A-", value: "A-" },
            { label: "B+", value: "B+" },
            { label: "B-", value: "B-" },
            { label: "AB+", value: "AB+" },
            { label: "AB-", value: "AB-" },
            { label: "O+", value: "O+" },
            { label: "O-", value: "O-" }
        ];
    }
    getDonatorList() {
        return [
            { label: "Selecione", value: "" },
            { label: "Sim", value: "S" },
            { label: "Não", value: "N" }
        ];
    }

    getProfessionStatusList() {
        return [
            { label: "Selecione", value: "" },
            { label: "Ativo", value: "Ativo" },
            { label: "Afastado", value: "Afastado" },
            { label: "Aposentado", value: "Aposentado" },
            { label: "Autônomo", value: "Autônomo" },
            { label: "Desempregado", value: "Desempregado" }
        ];
    }

    getMembersByBirthdayMonth(month) {
        return this.get(`${this.apiurl}/birthday?month=${month}`);
    }

    getMembersByBirthdayPeriod(startDate, endDate) {
        return this.get(`${this.apiurl}/birthday-period?startDate=${startDate}&endDate=${endDate}`);
    }

    getMembersByInitiationPeriod(startDate, endDate) {
        return this.get(`${this.apiurl}/initiation-period?startDate=${startDate}&endDate=${endDate}`);
    }

    getMembersByFiliationPeriod(startDate, endDate) {
        return this.get(`${this.apiurl}/filiation-period?startDate=${startDate}&endDate=${endDate}`);
    }

    getEducationList() {
        return [
            { label: "Selecione", value: "" },
            { label: "Ensino Fundamental - Incompleto", value: "Fundamental incompleto" },
            { label: "Ensino Fundamental - Completo", value: "Fundamental completo" },
            { label: "Ensino Médio - Incompleto", value: "Médio incompleto" },
            { label: "Ensino Médio - Completo", value: "Médio completo" },
            { label: "Superior - Incompleto", value: "Superior incompleto" },
            { label: "Superior - Completo", value: "Superior completo" },
            { label: "Pós-Graduação - Incompleto", value: "Pós-Graduação incompleto" },
            { label: "Pós-Graduação - Completo", value: "Pós-Graduação completo" },
            { label: "Mestrado - Incompleto", value: "Mestrado incompleto" },
            { label: "Mestrado - Completo", value: "Mestrado completo" },
            { label: "Doutorado - Incompleto", value: "Doutorado incompleto" },
            { label: "Doutorado - Completo", value: "Doutorado completo" },
            { label: "Pós-Doutorado - Incompleto", value: "Pós-Doutorado incompleto" },
            { label: "Pós-Doutorado - Completo", value: "Pós-Doutorado completo" },
        ];
    }

    new(member) {
        return this.post(`${this.apiurl}/new`, member);
    }

    update(member) {
        return this.put(`${this.apiurl}/${member.id}`, member);
    }

    deleteMember(id) {
        return this.delete(`${this.apiurl}/${id}`);
    }
}
export default MemberService;
