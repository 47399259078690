// PaymentRegister.js

import React, { useState, useEffect, useMemo } from "react";
import { Table, Button, Form, Row, Col, Spinner } from 'react-bootstrap';
import FormGroup from "../../components/form-group";
import { messageSuccess, messageError } from "../../components/toastr";
import TaxesPaymentsService from "../../app/service/taxesPaymentsService";
import TaxesReferenceService from "../../app/service/taxeReferenceService";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button as PrimeButton } from 'primereact/button';
import { ptBR } from 'date-fns/locale';


const monthOptions = [
    { label: 'Janeiro', value: 1 },
    { label: 'Fevereiro', value: 2 },
    { label: 'Março', value: 3 },
    { label: 'Abril', value: 4 },
    { label: 'Maio', value: 5 },
    { label: 'Junho', value: 6 },
    { label: 'Julho', value: 7 },
    { label: 'Agosto', value: 8 },
    { label: 'Setembro', value: 9 },
    { label: 'Outubro', value: 10 },
    { label: 'Novembro', value: 11 },
    { label: 'Dezembro', value: 12 }
];

// Formata número para R$ 0,00
const formatCurrency = (value) => {
    if (value === '' || value === null || value === undefined) return '';
    const number = typeof value === 'string'
        ? parseFloat(value.replace(/[R$\s.]/g, '').replace(',', '.'))
        : value;
    if (isNaN(number)) return '';
    return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number);
};

// Faz o parse de string com R$ e retorna number
const parseCurrency = (value) => {
    if (value === '' || value === null || value === undefined) return '';
    const number = typeof value === 'string'
        ? parseFloat(value.replace(/[R$\s.]/g, '').replace(',', '.'))
        : value;
    if (isNaN(number)) return '';
    return number;
};

const PaymentRegister = ({ taxes, members }) => {
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedTax, setSelectedTax] = useState('');
    const [addedTaxes, setAddedTaxes] = useState([]);

    const [focusedField, setFocusedField] = useState(null);
    const [originalValue, setOriginalValue] = useState({});

    const [loadingConfirm, setLoadingConfirm] = useState(false);

    const taxesPaymentsService = useMemo(() => new TaxesPaymentsService(), []);
    const taxesReferenceService = useMemo(() => new TaxesReferenceService(), []);

    // ---------------------------------------------------------------
    // 1) Adicionar Taxa
    //    - Buscar valor no ano atual, exibir na tabela
    // ---------------------------------------------------------------
    const handleTaxChange = (event) => {
        setSelectedTax(event.target.value);
    };

    const handleAddTax = async () => {
        if (!selectedTax) return;

        const taxId = parseInt(selectedTax, 10);
        const taxObj = taxes.find(t => t.id === taxId);

        // Se já existe no array, não duplica
        if (!taxObj || addedTaxes.find(t => t.id === taxObj.id)) return;

        try {
            // 1) Buscar valor no ano atual (se existir)
            const currentYear = new Date().getFullYear();
            let defaultValue = 0;
            const response = await taxesReferenceService.getByTaxIdAndYear(taxId, currentYear);
            if (response.data && response.data.value != null) {
                defaultValue = response.data.value;
            }

            // 2) Pegar default de dayExpire, startMonth, installments etc.
            const defaultDayExpire = taxObj.dayExpire && taxObj.dayExpire >= 1 && taxObj.dayExpire <= 28
                ? taxObj.dayExpire
                : 1;
            const defaultStartMonth = taxObj.startMonth != null ? taxObj.startMonth : 1;
            const defaultInstallments = taxObj.installments && taxObj.installments > 0
                ? taxObj.installments
                : 1;
            const isOthers = (taxObj.id === 99);

            // 3) Monta o novo item da lista
            const newTax = {
                ...taxObj,
                expireDate: null,
                editableValue: defaultValue,
                editableDayExpire: defaultDayExpire,
                editableStartMonth: defaultStartMonth,
                editableInstallments: defaultInstallments,
                editableParcelValue: defaultValue / defaultInstallments,
                otherDescription: isOthers ? '' : undefined
            };

            setAddedTaxes(prev => [...prev, newTax]);

        } catch (error) {
            console.error(error);
            messageError("Falha ao buscar valor da taxa para o ano atual.");
        }
    };

    const handleRemoveTax = (id) => {
        setAddedTaxes(prev => prev.filter(t => t.id !== id));
    };

    // ---------------------------------------------------------------
    // 2) Alterar Data (Pagamento / Vencimento)
    //    - Se for data de vencimento, buscar o valor do respectivo ano
    // ---------------------------------------------------------------
    const handleDateChange = async (date, taxId, field) => {
        let updated = [...addedTaxes];
        const idx = updated.findIndex(t => t.id === taxId);
        if (idx === -1) return;

        if (field === 'expireDate' && date) {
            const expireYear = date.getFullYear();

            try {
                const resp = await taxesReferenceService.getByTaxIdAndYear(taxId, expireYear);
                let newVal = 0;
                if (resp.data && resp.data.value != null) {
                    newVal = resp.data.value;
                }

                const installments = updated[idx].editableInstallments || 1;

                updated[idx] = {
                    ...updated[idx],
                    [field]: date,
                    editableValue: newVal,
                    editableParcelValue: newVal / installments
                };

            } catch (err) {
                console.error(err);
                messageError("Não foi possível buscar valor da taxa para " + expireYear);
                // Ao menos define a data
                updated[idx] = {
                    ...updated[idx],
                    [field]: date
                };
            }
        } else {
            updated[idx] = {
                ...updated[idx],
                [field]: date
            };
        }

        setAddedTaxes(updated);
    };

    // Digitando manualmente data de vencimento (dd/MM/yyyy)
    const handleRawExpireDateInput = (e, taxId) => {
        const val = e.target.value || '';
        if (!val.includes('/')) return;
        const parts = val.split('/');
        if (parts.length === 3) {
            const [dd, mm, yyyy] = parts;
            const d = parseInt(dd, 10), m = parseInt(mm, 10), y = parseInt(yyyy, 10);
            if (!isNaN(d) && !isNaN(m) && !isNaN(y)) {
                const parsed = new Date(y, m - 1, d);
                if (!isNaN(parsed.getTime())) {
                    handleDateChange(parsed, taxId, 'expireDate');
                }
            }
        }
    };

    // ---------------------------------------------------------------
    // 3) Se for "Outros" => Descrição custom
    // ---------------------------------------------------------------
    const handleOtherDescriptionChange = (e, taxId) => {
        const val = e.target.value;
        setAddedTaxes(prev => prev.map(t =>
            t.id === taxId ? { ...t, otherDescription: val } : t
        ));
    };

    // ---------------------------------------------------------------
    // 4) Valor Total e Valor de Parcela
    // ---------------------------------------------------------------
    const handleTotalValueFocus = (tax) => {
        setFocusedField({ id: tax.id, field: 'total' });
        const currentValue = tax.editableValue;
        const form = formatCurrency(currentValue);
        setOriginalValue(prev => ({ ...prev, [`${tax.id}-total`]: form }));
    };

    const handleTotalValueBlur = (tax) => {
        setFocusedField(null);
        const currentValue = tax.editableValue;
        if (currentValue === '' || currentValue === null || isNaN(currentValue)) {
            // Volta ao original
            const origVal = originalValue[`${tax.id}-total`] || '0';
            const parsedVal = parseCurrency(origVal);
            setAddedTaxes(prev => prev.map(t =>
                t.id === tax.id
                    ? {
                        ...t,
                        editableValue: parsedVal,
                        editableParcelValue: parsedVal / (t.editableInstallments || 1)
                    }
                    : t
            ));
        } else {
            // Recalcula parcela
            setAddedTaxes(prev => prev.map(t =>
                t.id === tax.id
                    ? {
                        ...t,
                        editableParcelValue: t.editableValue / (t.editableInstallments || 1)
                    }
                    : t
            ));
        }
    };

    const handleTotalValueChange = (e, taxId) => {
        const raw = e.target.value;
        const parsedVal = parseCurrency(raw);
        const finalVal = isNaN(parsedVal) ? 0 : parsedVal;
        setAddedTaxes(prev => prev.map(t =>
            t.id === taxId
                ? {
                    ...t,
                    editableValue: finalVal,
                    editableParcelValue: finalVal / (t.editableInstallments || 1)
                }
                : t
        ));
    };

    // Valor da Parcela
    const handleParcelValueFocus = (tax) => {
        setFocusedField({ id: tax.id, field: 'parcel' });
        const currentVal = tax.editableParcelValue;
        const form = formatCurrency(currentVal);
        setOriginalValue(prev => ({ ...prev, [`${tax.id}-parcel`]: form }));
    };

    const handleParcelValueBlur = (tax) => {
        setFocusedField(null);
        const currentParcelVal = tax.editableParcelValue;
        if (currentParcelVal === '' || currentParcelVal === null || isNaN(currentParcelVal)) {
            const origVal = originalValue[`${tax.id}-parcel`] || '0';
            const parsedVal = parseCurrency(origVal);
            setAddedTaxes(prev => prev.map(t =>
                t.id === tax.id
                    ? {
                        ...t,
                        editableParcelValue: parsedVal,
                        editableValue: parsedVal * (t.editableInstallments || 1)
                    }
                    : t
            ));
        } else {
            // Recalcula valor total
            setAddedTaxes(prev => prev.map(t =>
                t.id === tax.id
                    ? {
                        ...t,
                        editableValue: t.editableParcelValue * (t.editableInstallments || 1)
                    }
                    : t
            ));
        }
    };

    const handleParcelValueChange = (e, taxId) => {
        const raw = e.target.value;
        const parsedVal = parseCurrency(raw);
        const finalVal = isNaN(parsedVal) ? 0 : parsedVal;
        setAddedTaxes(prev => prev.map(t =>
            t.id === taxId
                ? {
                    ...t,
                    editableParcelValue: finalVal,
                    editableValue: finalVal * (t.editableInstallments || 1)
                }
                : t
        ));
    };

    // ---------------------------------------------------------------
    // 5) Day Expire, StartMonth, Installments
    // ---------------------------------------------------------------
    const handleDayExpireChange = (e, taxId) => {
        const val = parseInt(e.target.value, 10);
        if (isNaN(val) || val < 1 || val > 31) return; // Evita valores inválidos
        setAddedTaxes(prev => prev.map(t =>
            t.id === taxId ? { ...t, editableDayExpire: val } : t
        ));
    };

    const handleStartMonthChange = (e, taxId) => {
        const val = parseInt(e.target.value, 10);
        if (isNaN(val) || val < 1 || val > 12) return; // Evita valores inválidos
        setAddedTaxes(prev => prev.map(t =>
            t.id === taxId ? { ...t, editableStartMonth: val } : t
        ));
    };


    const handleInstallmentsChange = (e, taxId) => {
        const val = parseInt(e.target.value, 10);
        if (e.target.value == 1)
            handlePaymentTypeChange({ target: { value: 'single' } }, taxId);
        else
            handlePaymentTypeChange({ target: { value: 'recurring' } }, taxId);
        setAddedTaxes(prev => prev.map(t => {
            if (t.id === taxId) {
                const newIns = isNaN(val) || val <= 0 ? 1 : val;
                return {
                    ...t,
                    editableInstallments: newIns,
                    editableParcelValue: t.editableValue / newIns
                };
            }
            return t;
        }));
    };

    // ---------------------------------------------------------------
    // 6) Confirmar Pagamentos
    // ---------------------------------------------------------------
    const handleConfirm = async () => {
        if (selectedMembers.length === 0) {
            messageError('Selecione pelo menos um membro.');
            return;
        }
        if (addedTaxes.length === 0) {
            messageError('Nenhuma taxa adicionada.');
            return;
        }

        setLoadingConfirm(true);

        // Monta array de TaxPaymentDTO
        const taxesList = addedTaxes.map(t => ({
            id: t.id,
            dtExpire: t.expireDate ? t.expireDate.toISOString().split('T')[0] : null,
            description: (t.id === 99) ? t.otherDescription : null,
            value: t.editableValue || 0,
            dayExpire: t.editableDayExpire || 10,
            startMonth: t.editableStartMonth || (new Date().getMonth() + 1),
            installments: t.editableInstallments || 1
        }));


        try {
            if (selectAll) {
                const paymentRequest = { taxes: taxesList };
                await taxesPaymentsService.includeForAll(paymentRequest);
                messageSuccess('Pagamentos incluídos com sucesso para TODOS os membros!');
            } else {
                const includeMultipleRequest = {
                    memberIds: selectedMembers,
                    paymentRequest: { taxes: taxesList }
                };
                await taxesPaymentsService.includeForMultiple(includeMultipleRequest);
                messageSuccess('Pagamentos incluídos com sucesso para os membros selecionados!');
            }

            // Reseta tudo
            setSelectedMembers([]);
            setSelectAll(false);
            setAddedTaxes([]);
            setSelectedTax('');
        } catch (error) {
            const errorMessage = error.response?.data || 'Ocorreu um erro ao incluir pagamentos.';
            messageError(errorMessage);
            console.error("Erro ao incluir pagamentos:", error);
        } finally {
            setLoadingConfirm(false);
        }
    };

    // ---------------------------------------------------------------
    // 7) Selecionar membros
    // ---------------------------------------------------------------
    const handleSelectAllChange = (e) => {
        const checked = e.target.checked;
        setSelectAll(checked);
        if (checked) {
            const allIds = members.map(m => m.id);
            setSelectedMembers(allIds);
        } else {
            setSelectedMembers([]);
        }
    };

    const handleMemberCheckboxChange = (memberId) => {
        if (selectedMembers.includes(memberId)) {
            setSelectedMembers(prev => prev.filter(id => id !== memberId));
        } else {
            setSelectedMembers(prev => [...prev, memberId]);
        }
    };

    const handlePaymentTypeChange = (event, taxId) => {
        const value = event.target.value;
        setAddedTaxes(prev => prev.map(t => t.id === taxId ? { ...t, paymentType: value } : t));
    };

    // Se todos os membros estão selecionados, marca "Selecionar todos"
    useEffect(() => {
        if (selectedMembers.length === members.length && members.length > 0) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [selectedMembers, members]);

    // ---------------------------------------------------------------
    // 8) Render
    // ---------------------------------------------------------------
    return (
        <div style={{ marginTop: '2rem', marginLeft: '3%', marginRight: '3%' }}>
            <h5>Seleção de Membros</h5>

            <Row className="align-items-center mb-3">
                <Col xs={12} md={6}>
                    <Form.Group controlId="selectMembers">
                        <Form.Check
                            type="checkbox"
                            label="Selecionar Todos"
                            checked={selectAll}
                            onChange={handleSelectAllChange}
                        />
                        <div
                            style={{
                                maxHeight: '150px',
                                overflowY: 'auto',
                                border: '1px solid #ccc',
                                padding: '5px',
                                borderRadius: '4px',
                                width: '100%'
                            }}
                        >
                            {members.map(m => (
                                <Form.Check
                                    key={m.id}
                                    type="checkbox"
                                    label={m.name}
                                    checked={selectedMembers.includes(m.id)}
                                    onChange={() => handleMemberCheckboxChange(m.id)}
                                    style={{ marginLeft: '20px' }}
                                />
                            ))}
                        </div>
                    </Form.Group>
                </Col>

                <Col xs={12} md={6}>
                    <FormGroup label="Taxa:" htmlFor="selectTax">
                        <select
                            style={{ width: "100%", height: "38px" }}
                            className="form-control"
                            value={selectedTax}
                            onChange={handleTaxChange}
                        >
                            <option value="">Selecione</option>
                            {taxes.map(tax => (
                                <option key={tax.id} value={tax.id}>
                                    {tax.taxGroup + ' - ' + tax.description}
                                </option>
                            ))}
                        </select>
                    </FormGroup>
                    <div style={{ marginTop: '10px', marginBottom: '20px' }}>
                        <Button variant="primary" onClick={handleAddTax}>Adicionar Taxa</Button>
                    </div>
                </Col>
            </Row>



            {addedTaxes.length > 0 && (
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Descrição</th>
                            <th>Valor Total</th>
                            <th>Parcelas</th>
                            <th>Valor da Parcela</th>
                            <th>Tipo de Pagamento</th>
                            <th>Data</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {addedTaxes.map(tax => {
                            const isOthers = (tax.id === 99);

                            // Valor total
                            const currentValue = tax.editableValue;
                            const displayTotalValue = (
                                focusedField &&
                                focusedField.id === tax.id &&
                                focusedField.field === 'total'
                            )
                                ? (currentValue !== null && currentValue !== undefined
                                    ? currentValue.toString().replace('.', ',')
                                    : '')
                                : formatCurrency(currentValue) || '';

                            // Valor da parcela
                            const currentParcelValue = tax.editableParcelValue;
                            const displayParcelValue = (
                                focusedField &&
                                focusedField.id === tax.id &&
                                focusedField.field === 'parcel'
                            )
                                ? (currentParcelValue !== null && currentParcelValue !== undefined
                                    ? currentParcelValue.toString().replace('.', ',')
                                    : '')
                                : formatCurrency(currentParcelValue) || '';

                            return (
                                <tr key={tax.id}>
                                    {/* Campo "Outros" */}
                                    {isOthers ? (
                                        <td>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={tax.otherDescription}
                                                onChange={(e) => handleOtherDescriptionChange(e, tax.id)}
                                                placeholder="Informe a descrição da cobrança"
                                            />
                                        </td>
                                    ) : (
                                        <td>{tax.taxGroup + ' - ' + tax.description}</td>
                                    )}

                                    {!isOthers && addedTaxes.some(t => t.id === 99) && <td></td>}

                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={displayTotalValue}
                                            onFocus={() => handleTotalValueFocus(tax)}
                                            onBlur={() => handleTotalValueBlur(tax)}
                                            onChange={(e) => handleTotalValueChange(e, tax.id)}
                                        />
                                    </td>

                                    <td>
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={tax.editableInstallments}
                                            onChange={(e) => handleInstallmentsChange(e, tax.id)}
                                            min="1"
                                        />
                                    </td>

                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={displayParcelValue}
                                            onFocus={() => handleParcelValueFocus(tax)}
                                            onBlur={() => handleParcelValueBlur(tax)}
                                            onChange={(e) => handleParcelValueChange(e, tax.id)}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check disabled={tax.editableInstallments > 1} type="radio" label="Único" value="single" checked={tax.paymentType === 'single'} onChange={(e) => handlePaymentTypeChange(e, tax.id)} />
                                        <Form.Check disabled={tax.editableInstallments == 1} type="radio" label="Recorrente" value="recurring" checked={tax.paymentType === 'recurring'} onChange={(e) => handlePaymentTypeChange(e, tax.id)} />
                                    </td>
                                    <td>
                                        {tax.paymentType === 'single' ? (
                                            <td>
                                                <DatePicker
                                                    locale={ptBR}
                                                    selected={tax.expireDate}
                                                    onChange={(date) => handleDateChange(date, tax.id, 'expireDate')}
                                                    dateFormat="dd/MM/yyyy"
                                                    placeholderText="Data de Vencimento"
                                                    isClearable
                                                    className="form-control"
                                                    onChangeRaw={(e) => handleRawExpireDateInput(e, tax.id)}
                                                />
                                            </td>
                                        ) : (
                                            <>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Dia do Vencimento"
                                                    value={tax.editableDayExpire}
                                                    onChange={(e) => handleDayExpireChange(e, tax.id)}
                                                    min="1"
                                                    max="31"
                                                />
                                                <select
                                                    className="form-control"
                                                    value={tax.editableStartMonth}
                                                    onChange={(e) => handleStartMonthChange(e, tax.id)}
                                                >
                                                    {monthOptions.map(m => (
                                                        <option key={m.value} value={m.value}>{m.label}</option>
                                                    ))}
                                                </select>

                                            </>
                                        )}
                                    </td>



                                    <td>
                                        <PrimeButton
                                            icon="pi pi-times"
                                            rounded
                                            outlined
                                            severity="danger"
                                            aria-label="Remover"
                                            className="small-button btn btn-danger"
                                            style={{ fontSize: '12px', padding: '3px 5px' }}
                                            onClick={() => handleRemoveTax(tax.id)}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            )}

            {addedTaxes.length > 0 && (
                <div style={{ marginTop: '20px' }}>
                    <Button
                        variant="success"
                        onClick={handleConfirm}
                        disabled={loadingConfirm || selectedMembers.length === 0}
                    >
                        {loadingConfirm ? (
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="me-2"
                                />
                                Confirmando...
                            </>
                        ) : (
                            'Confirmar Inclusão'
                        )}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default PaymentRegister;
