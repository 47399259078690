
import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { messageSuccess, messageError } from '../components/toastr';
import UserService from '../app/service/userService';
import PRMTSService from '../app/service/prmtsService';
import { AuthContext } from "../security/AuthContext";
import MemberService from '../app/service/memberService';
import RolePermissionService from '../app/service/rolePermissionService';
import * as Constant from '../config/constants';

const TitleLMPHB = () => {
    const [canEdit, setCanEdit] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [dayTimeSession, setDayTimeSession] = useState({});
    const [dayTimeSessionDescription, setDayTimeSessionDescription] = useState('');
    const prmtsService = useMemo(() => new PRMTSService(), []);
    const userService = useMemo(() => new UserService(), []);
    const memberService = useMemo(() => new MemberService(), []);
    const rolePermissionService = useMemo(() => new RolePermissionService(), []);
    const { user, isAuthenticated } = useContext(AuthContext);


    const handleEditDayTime = () => {
        setShowModal(true);
    };

    const handleSave = async () => {
        setShowModal(false);

        const response = await prmtsService.getByType('DAYTIME-SESSION');

        if (response.data) {
            await prmtsService.update({
                type: dayTimeSession.type,
                description: dayTimeSessionDescription,
            }).then(response => {
                messageSuccess('Informações atualizadas com sucesso.');
            }).catch(error => {
                messageError(error.response.data);
            });
        } else {
            await prmtsService.new({
                type: 'DAYTIME-SESSION',
                description: dayTimeSessionDescription,
            }).then(response => {
                messageSuccess('Informações atualizadas com sucesso.');
            }).catch(error => {
                messageError(error.response.data);
            });
        }
    };

    const handleClose = () => {
        setShowModal(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem("token");
                if (isAuthenticated) {
                    if (user) {
                        if (user.role !== 1) {
                            const responseMember = await memberService.getById(user.member);
                            const responseCanEdit = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.EDIT_DAYTIME_SESSION);
                            setCanEdit(responseCanEdit.data);

                        } else {
                            setCanEdit(true);
                        }
                    } else {
                        setCanEdit(false);
                    }
                }

                const response = await prmtsService.getByType('DAYTIME-SESSION');
                if (response.data) {
                    setDayTimeSession(response.data);
                    setDayTimeSessionDescription(response.data.description);
                }

            } catch (error) {
                messageError('Erro ao buscar dados: ' + error.message);
            }
        };

        fetchData();
    }, [userService, prmtsService]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '50px' }}>
            <h1 style={{
                fontFamily: "'Great Vibes', cursive",
                fontSize: '2vw',
                textAlign: 'center',

            }}>
                <span style={{ fontSize: '2vw', }}>Loja Maçônica</span> <br /> Professor Hermínio Blackman,  nº 1761
            </h1>
            <h3 style={{
                fontSize: '1.2vw',
                textAlign: 'center',

            }}>FEDERADA AO GOB e JURISDICIONADA AO GOB-ES</h3>
            <h3 style={{
                fontSize: '1.5vw',
                textAlign: 'center',

            }}>R.E.A.A. - Reuniões às <strong>{dayTimeSessionDescription || ''}</strong>

                {canEdit && (
                    <Button
                        icon="pi pi-pencil"
                        className="btn btn-info"
                        style={{ fontSize: '0.5rem', padding: '0.25rem 0.1rem', marginLeft: '0.5rem' }}
                        onClick={handleEditDayTime}
                    />
                )}
            </h3>
            <h3 style={{
                fontSize: '1.2vw',
                textAlign: 'center',
            }}>Rua Presidente Lima, 1010, Centro, Vila Velha/ES. CEP 29.100-330</h3>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Informações</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formTitle">
                            <Form.Label>Dias da Semana e Horas</Form.Label>
                            <Form.Control type="text" value={dayTimeSessionDescription} onChange={e => setDayTimeSessionDescription(e.target.value)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Salvar Alterações
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    );
};

export default TitleLMPHB;

