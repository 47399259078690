import React, { useState, useEffect, useMemo, useContext } from 'react';
import Card from '../../components/card';
import FormGroup from '../../components/form-group';
import FormField from '../../components/form-field';
import { useNavigate } from 'react-router-dom';
import Container from '../../components/container';
import UserService from '../../app/service/userService';
import SpouseService from '../../app/service/spouseService';
import MemberService from '../../app/service/memberService';
import { messageSuccess, messageError } from '../../components/toastr';
import { format, parse } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import SelectMenu from "../../components/selectMenu";
import DatePicker from 'react-datepicker';
import { AuthContext } from '../../security/AuthContext';
import RolePermissionService from "../../app/service/rolePermissionService";
import * as Constant from '../../config/constants';

function SpouseRegister() {
    const { user, isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const userLogged = JSON.parse(localStorage.getItem('user_logged'));
    const spouseEdit = JSON.parse(localStorage.getItem('spouse-edit'));
    const spouseService = useMemo(() => new SpouseService(), []);
    const userService = useMemo(() => new UserService(), []);
    const memberService = useMemo(() => new MemberService(), []);
    const rolePermissionService = useMemo(() => new RolePermissionService(), []);

    const [visible, setVisible] = useState(false);

    const [id, setId] = useState(0);
    const [name, setName] = useState('');
    const [dateBorn, setDateBorn] = useState(null);
    const [formattedDateBorn, setFormattedDateBorn] = useState(null);
    const [dateMarry, setDateMarry] = useState(null);
    const [formattedDateMarry, setFormattedDateMarry] = useState(null);
    const [member, setMember] = useState('');
    const [memberList, setMemberList] = useState([]);
    const [email, setEmail] = useState('');
    const [phoneCell, setPhoneCell] = useState('');
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');

    const selectOption = { label: 'Selecione', value: '0' };
    const listSN = [
        { label: 'Não', value: 'N' },
        { label: 'Sim', value: 'S' }
    ];

    const validateEmail = (inputEmail) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(inputEmail);
    };

    const handleNameChange = (inputName) => {
        setName(inputName);
        setNameError('');
    };

    const handleEmailChange = (inputEmail) => {
        setEmail(inputEmail);

        if (!validateEmail(inputEmail)) {
            setEmailError('Digite um email válido');
        } else {
            setEmail(inputEmail);
            setEmailError('');
        }
        if (inputEmail === '') {
            setEmail(inputEmail);
            setEmailError('');
        }
    };

    const handleDateBornChange = (date) => {
        if (date && date instanceof Date && !isNaN(date)) {
            const formattedDate = format(date, 'yyyy-MM-dd', { locale: ptBR });
            setDateBorn(date);
            setFormattedDateBorn(formattedDate);
        } else {
            setDateBorn(null);
            setFormattedDateBorn(null);
        }
    };

    const handleDateMarryChange = (date) => {
        if (date && date instanceof Date && !isNaN(date)) {
            const formattedDate = format(date, 'yyyy-MM-dd', { locale: ptBR });
            setDateMarry(date);
            setFormattedDateMarry(formattedDate);
        } else {
            setDateMarry(null);
            setFormattedDateMarry(null);
        }
    };

    const register = async () => {
        if (name === '') {
            setNameError('Digite um nome válido');
            return;
        }

        if (emailError !== '') {
            messageError("Email inválido");
            return;
        }

        if (member === '') {
            messageError("Selecione um irmão");
            return;
        }

        setNameError('');
        setEmailError('');

        if (spouseEdit) {
            await spouseService.update({
                id: id,
                name: name,
                email: email,
                phoneCell: phoneCell,
                dtBorn: formattedDateBorn,
                dtMarry: formattedDateMarry,
                member: member
            }).then(response => {
                messageSuccess('Cunhada atualizada com sucesso.');
                navigate('/spouse-list');
            }).catch(error => {
                messageError(error.response.data);
            });
        } else {
            await spouseService.new({
                name: name,
                email: email,
                phoneCell: phoneCell,
                dtBorn: formattedDateBorn,
                dtMarry: formattedDateMarry,
                member: member
            }).then(response => {
                messageSuccess('Cunhada cadastrada com sucesso.');
                navigate('/spouse-list');
            }).catch(error => {
                messageError(error.response.data);
            });
        }
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = phoneNumber.replace(/\D/g, ''); // Remove qualquer caracter não numérico
        const match = cleaned.match(/^(\d{2})(\d{1,5})?(\d{0,4})$/);
        if (match) {
            const part1 = match[1] ? `(${match[1]})` : '';
            const part2 = match[2] ? ` ${match[2]}` : '';
            const part3 = match[3] ? `-${match[3]}` : '';
            return `${part1}${part2}${part3}`;
        }
        return phoneNumber; // Retorna o original se não houver correspondência
    };

    const handlePhoneCellChange = (e) => {
        const formattedPhone = formatPhoneNumber(e.target.value);
        setPhoneCell(formattedPhone); // Assume que você tem um estado chamado `phoneCell` para o telefone celular
    };


    useEffect(() => {
        const fetchData = async () => {
            if (isAuthenticated) {
                if (user) {
                    if (user.role !== 1) {
                        const responseMember = await memberService.getById(user.member);
                        const responseCanEdit = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.EDIT_SPOUSE);
                        setVisible(responseCanEdit.data);
                    } else {
                        setVisible(true);
                    }
                }

                if (spouseEdit) {
                    const response = await spouseService.getById(spouseEdit);
                    const spouseData = response.data;

                    setId(spouseData.id);
                    setName(spouseData.name);
                    setEmail(spouseData.email);
                    setPhoneCell(spouseData.phoneCell)
                    setMember(spouseData.member);

                    if (spouseData.dtBorn) {
                        setFormattedDateBorn(spouseData.dtBorn);
                        setDateBorn(new Date(spouseData.dtBorn[0], spouseData.dtBorn[1] - 1, spouseData.dtBorn[2]))
                    }

                    if (spouseData.dtMarry) {
                        setFormattedDateMarry(spouseData.dtMarry);
                        setDateMarry(new Date(spouseData.dtMarry[0], spouseData.dtMarry[1] - 1, spouseData.dtMarry[2]))
                    }
                }

            } else {
                navigate('/login');
                return;
            }

            const response = await memberService.getAll();
            const listMembers = [
                selectOption,
                ...response.data.map(item => ({
                    label: item.name,
                    value: item.id
                })).sort((a, b) => a.label.localeCompare(b.label))
            ];
            setMemberList(listMembers);
        };

        fetchData();
        //localStorage.removeItem("spouse-edit")

    }, [spouseEdit, memberService, navigate, userService, spouseService]);

    return (
        <div>
            <Container>
                <Card title="Cadastro de nova cunhada">
                    <Container>
                        <fieldset>
                            <FormGroup label="Irmão: *" htmlFor="selectMember">
                                <br />
                                <SelectMenu
                                    className="form-control"
                                    list={memberList}
                                    value={member}
                                    onChange={(e) => setMember(e.target.value)}
                                    style={{ maxWidth: '300px' }}
                                    disabled={!visible}
                                />
                            </FormGroup>
                            <br />
                            <FormGroup label="Nome: *" htmlFor="inputName" error={nameError}>
                                <input
                                    className={`form-control ${nameError ? 'is-invalid' : ''}`}
                                    type="text"
                                    id="inputName"
                                    name="name"
                                    value={name}
                                    placeholder="Digite o nome"
                                    onChange={(e) => handleNameChange(e.target.value)}
                                    style={{ maxWidth: '300px' }}
                                />
                                {nameError && (
                                    <div className="invalid-feedback">
                                        {nameError}
                                    </div>
                                )}
                            </FormGroup>
                            <br />
                            <FormGroup label="Data de Nascimento: " htmlFor="inputDateBorn">
                                <br />
                                <DatePicker
                                    selected={dateBorn}
                                    onChange={handleDateBornChange}
                                    dateFormat="dd/MM/yyyy"
                                    showTimeSelect={false}
                                    locale="pt-BR"
                                    placeholderText="DD/MM/AAAA"
                                    value={dateBorn}
                                />
                            </FormGroup>
                            <br />
                            <FormGroup label="Data de Casamento: " htmlFor="inputDateMarry">
                                <br />
                                <DatePicker
                                    selected={dateMarry}
                                    onChange={handleDateMarryChange}
                                    dateFormat="dd/MM/yyyy"
                                    showTimeSelect={false}
                                    locale="pt-BR"
                                    placeholderText="DD/MM/AAAA"
                                    value={dateMarry}
                                />
                            </FormGroup>
                            <br />
                            <FormGroup label="Email: " htmlFor="inputEmail" error={emailError}>
                                <input
                                    className={`form-control ${emailError ? 'is-invalid' : ''}`}
                                    type="email"
                                    id="inputEmail"
                                    name="email"
                                    value={email}
                                    placeholder="Digite o email"
                                    onChange={(e) => handleEmailChange(e.target.value)}
                                    style={{ maxWidth: '300px' }}
                                />
                                {emailError && (
                                    <div className="invalid-feedback">
                                        {emailError}
                                    </div>
                                )}
                            </FormGroup>
                            <br />
                            <FormField style={{ marginRight: '2px' }}> {/* Estilo inline para reduzir o espaço */}
                                <label htmlFor="inputPhoneCell">Telefone Celular:</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    style={{ width: '22%' }}
                                    id="inputPhoneCell"
                                    name="phoneCell"
                                    placeholder="Digite o Celular"
                                    value={phoneCell}
                                    onChange={handlePhoneCellChange}
                                />
                            </FormField>
                        </fieldset>
                    </Container>
                </Card>

                <button onClick={register} className="btn btn-success">
                    Cadastrar
                </button>
                {' '}
                <button onClick={e => navigate('/spouse-list')} className="btn btn-danger">
                    Cancelar
                </button>
            </Container>
        </div>
    );
}

export default SpouseRegister;
