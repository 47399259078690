// pdfHeaderPdfMake.js

export function pdfHeader(dayTimeSessionDescription) {
    const headerContent = [
        { text: "Loja Maçônica Professor Hermínio Blackman, nº 1761", style: 'headerMain' },
        { text: "FEDERADA AO GOB e JURISDICIONADA AO GOB-ES", style: 'headerSub' },
        { text: `Rito EAA - Reuniões às ${dayTimeSessionDescription}`, style: 'headerSub' },
        { text: "Rua Presidente Lima, 1010, Centro, Vila Velha/ES. CEP 29.100-330", style: 'headerSub', margin: [0, 0, 0, 10] }
    ];

    const headerStyles = {
        headerMain: {
            fontSize: 28,
            alignment: 'center',
            margin: [0, 0, 0, 5],
            font: 'GreatVibes',
        },
        headerSub: {
            fontSize: 10,
            alignment: 'center',
            margin: [0, 0, 0, 2],
            // font: 'Roboto', // Opcional, já que Roboto é a fonte padrão
        },
    };

    return { headerContent, headerStyles };
}
